import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ title, description, lang, image, schema, keywords }) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    defaultTitleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    defaultKeywords,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    keywords: keywords || defaultKeywords,
  }

  let defaultSchema = [
    {
      "@context": "http://schema.org",
      "@type": "Florist",
      "@id": "https://www.heartbeeflowers.com",
      name: "HeartBee Flowers",
      image: seo.image,
      telephone: "01962 621 097",
      email: "hello@heartbeeflowers.com",
      address: {
        "@type": "PostalAddress",
        streetAddress: "48 Oliver's Battery Rd North",
        addressLocality: "Winchester",
        addressRegion: "Hampshire",
        postalCode: "SO22 4JB",
      },
      openingHoursSpecification: [
        {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: ["Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          opens: "10:30",
          closes: "17:30",
        },
      ],
    },
  ]

  if (schema) {
    defaultSchema = [...defaultSchema, ...schema]
  }

  const canonical = pathname ? seo.url : null
  const titleTemplate = pathname === "/" ? seo.title : defaultTitleTemplate

  return (
    <Helmet
      title={seo.title}
      htmlAttributes={{ lang }}
      titleTemplate={titleTemplate}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
    >
      <meta name="description" content={seo.description} />
      {seo.keywords && <meta name="keywords" content={seo.keywords} />}
      <meta name="image" content={seo.image} />

      <script type="application/ld+json">
        {JSON.stringify(defaultSchema)}
      </script>

      <meta property="theme-color" content="#cc5500" />
      <meta property="og:site_name" content="HeartBee Flowers" />
      {seo.url && <meta property="og:url" content={seo.url} />}
      <meta property="og:type" content="website" />

      {seo.title && (
        <meta
          property="og:title"
          content={`${seo.title} · HeartBee Flowers · Winchester`}
        />
      )}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {seo.title && (
        <meta
          name="twitter:title"
          content={`${seo.title} · HeartBee Flowers · Winchester`}
        />
      )}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
}

Seo.defaultProps = {
  lang: "en",
  title: null,
  description: null,
  image: null,
}

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        defaultTitle: title
        defaultTitleTemplate: titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        defaultKeywords: keywords
      }
    }
  }
`

export default Seo
